//width
$width: 80vw;
$nav-height: 6rem;

//COLOUR
$color-primary: #354259;
$color-secondary: #678983;
$color-tertiary: #e6ddc4;
$color-quaternary: #f0e9d2;

$color-title: #dededd;
$color-grey-dark: #4a4a4a;
$color-red: #ce7777;
$background-color: #fff;

//font
@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Fira+Code:wght@400;600&family=Source+Sans+Pro:wght@300;400;700&family=Vollkorn&display=swap");

$font-title: "DM Serif Display", serif;
$font-basic: "Source Sans Pro", sans-serif;
$font-code: "Fira Code", monospace;
