/* Media Query Manager
1em = 16px
[Normal Style]
0    - 600px:    37.5em           phone    //8
600  - 900px:    56.25em          tab-port //9
900  - 1200px:   75em             tab-land //10px
1200 - 1800px:   -                [NORMAL] //10px
1800px +:        112.5em          big-desktop */

@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media (max-width: 37.5em) {
      @content;
    }
  }
  @if $breakpoint == tab-port {
    @media (min-width: 37.5em) {
      @content;
    }
  }
  @if $breakpoint == tab-land {
    @media (min-width: 56.25em) {
      @content;
    }
  }
  @if $breakpoint == desktop {
    @media (min-width: 75em) {
      @content;
    }
  }
  @if $breakpoint == big-desktop {
    @media (min-width: 112.5em) {
      @content;
    }
  }
}

@mixin nav-title {
  color: $color-grey-dark;
  font-size: 2rem;
  font-family: $font-basic;
  letter-spacing: 2px;
  cursor: pointer;
}

@mixin title {
  color: $color-title;
  font-size: 4.5rem;
  font-family: $font-title;
  letter-spacing: 3px;
}
