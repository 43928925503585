.footer {
  font-family: $font-basic;
  padding: 2rem;
  text-align: center;
  margin-top: 3rem;
  height: 10rem;

  &-name {
    font-size: 1.5rem;

    margin: 1rem 0;
  }

  &-icon {
    font-size: 2.5rem;
    margin: 0 0.5rem;
    color: $color-primary;

    &:hover {
      color: $color-secondary;
    }
  }
}
