* {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  box-sizing: border-box;

  @include respond(phone) {
    font-size: 50%;
  }

  @include respond(tab-port) {
    font-size: 56.25%;
  }

  @include respond(tab-land) {
    font-size: 62.5%;
  }

  @include respond(big-desktop) {
    font-size: 68.75%;
  }

  // @include respond(phone) {
  //   font-size: 43.75%;
  // }
}
