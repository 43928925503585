.navigation {
  font-family: $font-basic;
  letter-spacing: 2px;

  &__button {
    position: fixed;
    top: 2.5rem;
    right: 2.5rem;

    background-color: #fff;
    height: 6rem;
    width: 6rem;
    border-radius: 50%;

    box-shadow: 0 1rem 3rem rgba($color-grey-dark, 0.1);
    text-align: center;
    cursor: pointer;
    z-index: 2000;
  }

  &__nav {
    background-color: #fff;
    height: 50vh;
    z-index: 1500;

    opacity: 0;
    width: 0;

    transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  &__list {
    position: absolute;
    list-style: none;
    text-align: center;
    width: 100%;
  }

  &__item {
    margin: 1rem;
  }

  &__link {
    display: inline-block;
    font-size: 3rem;
    padding: 1rem 2rem;
    color: $color-grey-dark;
    text-decoration: none;
    background-image: linear-gradient(
      120deg,
      transparent 0%,
      transparent 50%,
      #fff 50%
    );
    background-size: 220%;
    transition: all 0.4s;
    cursor: pointer;
  }

  //ICON
  &__icon {
    position: relative;
    margin-top: 3rem;

    &,
    &::before,
    &::after {
      width: 3rem;
      height: 2px;
      background-color: rgba($color-grey-dark, 0.7);
      display: inline-block;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
      transition: all 0.2s;
    }

    &::before {
      top: -1rem;
    }
    &::after {
      top: 1rem;
    }
  }
}

.nav {
  width: 60vw;
  max-width: 50rem;
  height: 100vh;
  background-color: rgba(#fff, 0.9);

  position: fixed;
  right: -120%;
  top: 0;
  transition: all 0.4s ease-in;

  &__show {
    right: 0;
  }

  border-left: 1px solid;
  border-image: linear-gradient(to bottom, $color-secondary, $color-tertiary) 1;

  &__logo {
    position: absolute;
    left: 4.5rem;
    top: 4rem;
    width: 6rem;
  }

  &__title {
    font-size: 3rem;
  }

  &__list {
    color: rgba(#fff, 0.85);
    list-style: none;
    margin-top: 15rem;

    padding: 3rem 0 3rem 6.5rem;

    background-color: $color-primary;
  }

  &__item {
    font-size: 2.25rem;
    margin: 3rem auto;

    cursor: pointer;

    a {
      text-decoration: none;
      color: inherit;
    }
  }

  &__links {
    width: max-content;
    margin-top: 3rem;
    margin-left: 5rem;

    &-icon {
      font-size: 3rem;
      margin: 0 1rem;
      color: $color-primary;
    }
  }
}
