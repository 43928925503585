//TYPOGRAPHY
.project-title {
  @include title();
  text-align: center;
}

.code {
  font-size: 1rem;
  font-family: $font-code;
  background-color: rgb(240, 238, 238);
  padding: 0 0.5rem;
  margin: 0 1px;
  border-radius: 0.5rem;
  //font-weight: 600;
  letter-spacing: 2px;
}

//UTILITY
.margin-top {
  margin-top: 3rem;
}

//PJ DETAIL
#yomulog {
  color: $color-grey-dark;
  font-family: $font-basic;
  font-size: 1.25rem;
  line-height: 2;
  width: 80vw;
  max-width: 110rem;

  margin: 0 auto;

  @include respond(phone) {
    width: 85vw;
  }

  a {
    color: $color-red;
  }
  a:hover {
    color: $color-primary;
  }
}

.pj-navigation {
  height: $nav-height;
  text-align: right;
  padding: 2.5rem;
}

.pjd {
  padding: 0 5rem;
  position: relative;

  @include respond(tab-port) {
    padding: 0 1rem;
  }

  @include respond(phone) {
    padding: 0 0.5rem;
  }

  &-view {
    text-align: center;
  }

  &-screenshot {
    margin: 3rem auto 3rem auto;
    width: 43rem;
    border-radius: 1rem;
    cursor: pointer;
  }

  &-multi-div {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
  }

  &-screenshot-row {
    height: 40rem;
    margin: 0 auto;
  }

  &-screenshot-gif {
    border-radius: 1rem;
    margin: 3rem auto;
    width: 50rem;
    height: 30rem;

    @include respond(phone) {
      width: 40rem;
      height: 24rem;
    }
  }

  &-icon {
    font-size: 2.5rem;
    margin: 0 1.5rem;

    color: $color-primary;

    &:hover {
      color: $color-secondary;
    }
  }

  &-title {
    font-size: 3rem;
    color: $color-title;
    position: relative;
    top: 2rem;

    &-border {
      border-bottom: 1px solid $color-title;

      margin-bottom: 2.5rem;
    }
  }

  &-duration {
    text-align: center;

    &__time {
      font-size: 1.7rem;
    }
  }

  &-table {
    margin: 0 auto;
    &-header {
      font-size: 1.5rem;
      width: 10rem;
      height: 5rem;
    }

    &-icon {
      font-size: 4rem;
      text-align: center;
      line-height: 1;

      &__react {
        color: aqua;
      }

      &__sass {
        color: #bf4080;
      }

      &__firebase {
        color: #ffcd4a;
      }
    }

    &-detail {
      line-height: 1;
      text-align: center;
    }
  }

  &-textbox {
    padding: 0 3rem;
    margin-bottom: 2.5rem;
    //background-color: yellow;
  }

  &-list {
    padding: 0 2rem;
  }

  &-subtitle {
    margin: 2.5rem 0 1rem 0;
    font-weight: bold;
    font-size: 1.75rem;
    color: rgba($color-grey-dark, 0.8);
  }

  &-p-title {
    font-weight: bold;
    color: $color-red;
    font-size: 1.5rem;
  }

  &-struggles {
    &-p {
      margin-bottom: 2rem;
    }

    &-notation {
      font-size: 1rem;
    }

    &-figma {
      width: 45rem;
      border-radius: 1rem;
      margin-bottom: 2rem;
    }

    &-item {
      margin-bottom: 2rem;
    }
  }
}

.pj-back {
  @include nav-title();
}

//TODO
.todo {
  &-screenshot-container {
    height: 30rem;
    margin: 3rem auto;
  }

  &-mobile {
    display: inline-block;
    width: 20rem;
    border-radius: 1rem;
    // margin: 1.5rem 1rem;
  }

  &-dark {
    position: relative;
    left: -7rem;
    top: 5rem;
  }
}
