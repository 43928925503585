#project {
  background-color: #fff;
  font-family: $font-basic;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.project {
  &__border {
    margin: 2rem;
    border-radius: 1rem;
    background-image: linear-gradient(
      to right bottom,
      $color-tertiary,
      $color-secondary
    );
  }

  height: 99.5%;
  background-color: white;
  border-radius: 1rem;
  color: $color-grey-dark;
  padding: 3rem;
  margin: 1px;
  text-align: center;
  width: 35rem;

  @include respond(tab-land) {
    width: 45rem;
  }

  &__img {
    border-radius: 1rem;
    width: 100%;
  }

  &__title {
    margin: 1.5rem 0;
  }

  &__link {
    font-size: 3rem;
    font-weight: bold;

    text-decoration: none;
    color: inherit;

    transition: all 0.25s ease-out;

    &:hover {
      color: $color-secondary;
    }
  }

  &__detail {
    margin: 0.5rem 0;
    font-size: 1.25rem;

    &--tool {
      color: rgba($color-grey-dark, 0.8);
    }
  }

  &__icon {
    font-size: 2rem;
    color: $color-primary;
    margin: 2rem 0.75rem 0 0.75rem;
    cursor: pointer;

    transition: all 0.25s ease-out;

    &:hover {
      color: $color-secondary;
      position: relative;
      top: 3px;
    }

    &:active {
      top: 5px;
    }

    //スマホ用
    &:link,
    &:visited {
      color: $color-primary;
    }
  }
}

//
.blur {
  filter: blur(8px);
  -webkit-filter: blur(8px);
}
